import React, { useEffect, useState } from 'react';
import {
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  TextField,
} from '@mui/material';
import { getProductsAdmin, enabled } from '../../client/apiProducts';

// Componente principal
export const ProductToggle = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  // Obtener productos de la API
  const fetchProducts = async () => {
    try {
      const response = await getProductsAdmin();
      setProducts(response);
      setFilteredProducts(response); // Inicializar productos filtrados
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  // Actualizar estado de habilitación de un producto
  const toggleProduct = async (productId, isEnabled) => {
    try {
      await enabled(productId, isEnabled);
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId ? { ...product, enabled: isEnabled } : product
        )
      );
      setFilteredProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId ? { ...product, enabled: isEnabled } : product
        )
      );
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  // Filtrar productos
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    const filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(value) ||
        product.sku.toLowerCase().includes(value) ||
        product.brand.toLowerCase().includes(value)
    );
    setFilteredProducts(filtered);
  };

  // Cargar productos al montar el componente
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Typography variant="h4" gutterBottom>
        Productos
      </Typography>

      {/* Input de búsqueda */}
      <TextField
        label="Buscar"
        variant="outlined"
        value={search}
        onChange={handleSearch}
        sx={{ marginBottom: 2, width: '50%' }}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ maxWidth: 1200 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"><strong>ID</strong></TableCell>
                <TableCell align="center"><strong>SKU</strong></TableCell>
                <TableCell align="center"><strong>Name</strong></TableCell>
                <TableCell align="center"><strong>Type</strong></TableCell>
                <TableCell align="center"><strong>Brand</strong></TableCell>
                <TableCell align="center"><strong>Enabled</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts.map((product) => (
                <TableRow key={product.id}>
                  <TableCell align="center">{product.id}</TableCell>
                  <TableCell align="center">{product.sku}</TableCell>
                  <TableCell align="center">{product.name}</TableCell>
                  <TableCell align="center">{product.type}</TableCell>
                  <TableCell align="center">{product.brand}</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={product.enabled}
                      onChange={() => toggleProduct(product.id, !product.enabled)}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
              {filteredProducts.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Producto no encontrado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
