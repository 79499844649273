import React, { useState, useRef } from "react";
import { 
  Box, 
  Button, 
  Typography, 
  Input, 
  Paper, 
  CircularProgress, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions 
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { uploadFile } from "../../client/apiProducts";

export const UploadPriceFilePage = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false); // Estado para el diálogo de éxito
  const fileInputRef = useRef(null); // Ref para el campo de archivo
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setDialogOpen(false); // Cierra el diálogo si se selecciona un nuevo archivo
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Por favor, selecciona un archivo para subir.");
      return;
    }

    setLoading(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const [headers] = jsonData;
      const requiredColumns = ["sku", "price", "price_membership", "cost"];
      const hasRequiredColumns = requiredColumns.every((col) => headers.includes(col));

      if (!hasRequiredColumns) {
        alert("El archivo no tiene el formato correcto. Asegúrate de que contiene las columnas: sku, price, price_membership, cost.");
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await uploadFile(formData);
        if (response.message === "Updated successfully") {
          setDialogOpen(true); // Abre el diálogo de éxito
          handleClearSelection();
        }
      } catch (error) {
        alert("Hubo un problema al cargar el archivo. Inténtalo de nuevo.");
      } finally {
        setLoading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleClearSelection = () => {
    setFile(null);
    fileInputRef.current.value = ""; // Restablecer el valor del campo de archivo
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Cerrar el diálogo de éxito
  };

  return (
    <Box sx={{ textAlign: "center", mt: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Subir archivo Excel para cambio de precios
      </Typography>
      
      <Paper elevation={3} sx={{ padding: 3, display: "inline-block", mb: 3, width: "80%" }}>
        <Box sx={{ mb: 2 }}>
          <Input type="file" onChange={handleFileChange} inputRef={fileInputRef} />
        </Box>

        <Box display="flex" justifyContent="center" gap={2} alignItems="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearSelection}
            disabled={!file || loading}
          >
            Limpiar Selección
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleUpload}
            disabled={!file || loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? "Cargando..." : "Subir y Actualizar Precios"}
          </Button>
        </Box>
      </Paper>

      {/* Botón para regresar a Admin */}
      <Box sx={{ mt: 3, marginBottom: 2 }}>
        <Button
          variant="outlined"
          onClick={() => navigate("/admin")}
          sx={{
            color: "green",
            borderColor: "green",
            "&:hover": {
              borderColor: "darkgreen",
              color: "darkgreen",
            },
          }}
        >
          Volver a Admin Panel
        </Button>
      </Box>

      {/* Diálogo de éxito */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Guardado Exitoso</DialogTitle>
        <DialogContent>
          <Typography>Los precios se han actualizado correctamente.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
