import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import Auth from "../Auth/Auth";
import { getMembershipsTypes, createWithCoupon } from "../../client/apiMemberships";
import { createTransactionMembership } from "../../client/apiWebpayService";
import MembershipCard from "./MembershipCard";
import PaymentMethods from "./PaymentMethods";
import CouponInput from "./CouponInput";
import webpayImage from "../../assets/logo-webpay-plus.png";
import { useNavigate } from "react-router-dom";

export const MembershipPage = () => {
  const { user, userId } = useContext(AuthContext);
  const [authOpen, setAuthOpen] = useState(false);
  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("webpay");
  const [filter, setFilter] = useState("MENSUAL");
  const [couponAmount, setCouponAmount] = useState(0);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Estado del loader
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        const data = await getMembershipsTypes();
        setMemberships(data);
      } catch (error) {
        console.error("Error loading memberships:", error);
      }
    };

    fetchMemberships();
  }, []);

  const handleMembershipSelection = (membership) => {
    setSelectedMembership(membership);
  };

  const handleCheckout = async () => {
    if (!user) {
      setAuthOpen(true);
      return;
    }

    if (!selectedMembership) {
      alert("Por favor, selecciona una membresía.");
      return;
    }

    try {
      setIsLoading(true); // Activar loader
      localStorage.setItem("membershipId", selectedMembership.id);
      localStorage.setItem("userId", userId);
      localStorage.setItem("coupon", couponAmount || "");

      if (paymentMethod === "webpay" && !isCouponValid) {
        const response = await createTransactionMembership(selectedMembership.amount);
        if (response.url && response.token) {
          window.location.href = `${response.url}?token_ws=${response.token}`;
        }
      } else if (paymentMethod === "transfer" && !isCouponValid) {
        navigate("/transfer-membership");
      }

      if (isCouponValid) {
        await createWithCoupon(selectedMembership.id, userId, coupon);
        navigate("/success-coupon-membership");
      }
    } catch (error) {
      console.error("Error initiating the transaction:", error);
    } finally {
      setIsLoading(false); // Desactivar loader si ocurre un error
    }
  };

  const filteredMemberships = memberships.filter(
    (membership) => membership.type === filter
  );

  const paymentMethods = [
    {
      value: "webpay",
      label: "Pago con Webpay",
      description: "Paga de forma segura con tu tarjeta de crédito o débito.",
      image: webpayImage,
    },
    {
      value: "transfer",
      label: "Transferencia Bancaria",
      description: "Realiza el pago directamente desde tu cuenta bancaria.",
    },
  ];

  const calculateTotal = (membership, couponAmount) => {
    if (!membership) return "0";
    if (isCouponValid) return "0"; // Si el cupón es válido, el total es 0
    const total = Math.max(
      0,
      (membership.amount || 0) - (couponAmount || 0)
    );
    return Number(total).toLocaleString("es-CL");
  };

  return (
    <Container
      sx={{
        mt: 4,
        mb: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: "bold", color: "green" }}>
        Selecciona tu Membresía
      </Typography>

      <ToggleButtonGroup
        value={filter}
        exclusive
        onChange={(e, newFilter) => setFilter(newFilter || filter)}
        aria-label="Filtro de membresía"
      >
        <ToggleButton value="MENSUAL">Mensual</ToggleButton>
        <ToggleButton value="ANUAL">Anual</ToggleButton>
      </ToggleButtonGroup>

      <Grid container spacing={4} justifyContent="center">
        {filteredMemberships.map((membership) => (
          <Grid item xs={12} sm={6} md={4} key={membership.id}>
            <MembershipCard
              membership={membership}
              onClick={handleMembershipSelection}
              isSelected={selectedMembership?.id === membership.id}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Selecciona el Método de Pago
            </Typography>
            <PaymentMethods
              methods={paymentMethods}
              selectedMethod={paymentMethod}
              onSelect={setPaymentMethod}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <CouponInput
              selectedMembership={selectedMembership}
              onApplyCoupon={(amount, isValid) => {
                setCouponAmount(amount);
                setIsCouponValid(isValid);
              }}
              setIsCouponValid={setIsCouponValid}
              user={user}
              setAuthOpen={setAuthOpen}
              setCoupon={setCoupon}
              coupon={coupon}
            />
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Total: ${calculateTotal(selectedMembership, couponAmount)}
              </Typography>
              <Button
                variant="contained"
                color="success"
                onClick={handleCheckout}
                disabled={!selectedMembership || !paymentMethod || isLoading}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : "Comprar"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Auth open={authOpen} onClose={() => setAuthOpen(false)} />
    </Container>
  );
};
