// Orders.js
import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Button, Fab, useMediaQuery, useTheme } from '@mui/material';
import { OrderDetailDialog } from './OrderDetailDialog';

export const Orders = ({ orders }) => {
  console.log(orders);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDialog = (order) => {
    setSelectedOrder(order);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOrder(null);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pendiente de pago':
        return 'error';
      case 'pagado':
        return 'warning';
      case 'entregado':
        return 'success';
      default:
        return 'info';
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Mis Compras
      </Typography>
      <Grid container spacing={2}>
        {orders.map((order, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                border: '1px solid #4caf50',
                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row', // Cambiar a columna en móvil
                  justifyContent: isMobile ? 'center' : 'space-between',
                  alignItems: isMobile ? 'flex-start' : 'center',
                }}
              >
                <Box sx={{ width: '100%', mb: isMobile ? 2 : 0 }}>
                <Typography variant="h6" color="textSecondary" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                    N° Pedido: {order.id}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                    Fecha de compra: {order.created_at}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
                    Total pagado: ${order.final_amount.toLocaleString('es-CL')}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
                    Dirección de envío: {order.shipping_address}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
                    Productos comprados: {order.total_products.toLocaleString('es-CL')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    width: isMobile ? '100%' : 'auto', // Ancho completo en móvil
                  }}
                >
                  <Fab
                    variant="extended"
                    size="small"
                    color={getStatusColor(order.status)}
                    aria-label="status"
                    sx={{
                      height: isMobile ? 40 : 38,
                      minHeight: '24px',
                      fontSize: '0.75rem',
                      padding: '0 8px',
                      mb: isMobile ? 1 : 2, // Margen inferior en móvil
                    }}
                  >
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {order.status}
                    </Typography>
                  </Fab>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      mt: isMobile ? 1 : 0, // Espacio superior en móvil
                      width: isMobile ? '100%' : 'auto', // Ancho completo en móvil
                    }}
                    onClick={() => handleOpenDialog(order)}
                  >
                    Ver detalle
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {selectedOrder && (
        <OrderDetailDialog
          open={openDialog}
          onClose={handleCloseDialog}
          order={selectedOrder}
        />
      )}
    </Box>
  );
};
