import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import { CartContext } from '../../context/CartContext';
import { getMembershipBalanceClient } from '../../client/apiMemberships';

export const PaymentSuccessCouponPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [membershipBalance, setMembershipBalance] = useState(null);
  const [timer, setTimer] = useState(10);
  const { setBalance } = useContext(AuthContext); 
  const { setCartItems } = useContext(CartContext);

  const adjustCartPrices = useCallback((newBalance, cartItems) => {
    let remainingBalance = newBalance;
    const updatedCartItems = cartItems.map(item => {
      const membershipPrice = parseInt(item.price_membership);
      if (!item.isMembership && remainingBalance >= membershipPrice * item.quantity) {
        remainingBalance -= membershipPrice * item.quantity;
        let updatedId = item.id.includes("_normal") ? item.id.replace("_normal", "") : item.id;
        return {
          ...item,
          id: updatedId,
          price: membershipPrice,
          isMembership: true,
        };
      }
      return item;
    });

    setBalance(remainingBalance);
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    localStorage.setItem("balance", remainingBalance);
  }, [setBalance, setCartItems]);

  const calculateMembershipUsed = useCallback((cartItems) => {
    return cartItems.reduce((total, item) => {
      if (item.isMembership) {
        return total + (parseInt(item.price_membership) * item.quantity);
      }
      return total;
    }, 0);
  }, []);

  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (savedCartItems && savedCartItems.length > 0) {
      setCartItems(savedCartItems);
    } else {
      console.error("No se encontraron productos en el carrito.");
    }
  }, [setCartItems]);

  useEffect(() => {
    const processPayment = async () => {
      const clientId = parseInt(localStorage.getItem("userId"));
        try {
          const response = await getMembershipBalanceClient(clientId);
          const fullBalance = response;
          const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));

            if (savedCartItems && savedCartItems.length > 0) {
              const membershipUsed = calculateMembershipUsed(savedCartItems);
              const remainingBalance = fullBalance - membershipUsed;
              setMembershipBalance(remainingBalance);
              adjustCartPrices(remainingBalance, savedCartItems);
            } else {
              setMembershipBalance(fullBalance);
              setBalance(fullBalance);
              localStorage.setItem("balance", fullBalance);
            }

            setSuccess(true);
        } catch (error) {
          console.error("Error al confirmar la transacción:", error);
          setError(true);
        } finally {
          setLoading(false);
        }
    };

    processPayment();
  }, [location.search, adjustCartPrices, calculateMembershipUsed, setBalance]);

  useEffect(() => {
    if (success || error) {
      if (timer > 0) {
        const countdown = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
      } else {
        navigate("/");
      }
    }
  }, [timer, navigate, success, error]);

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: "background.paper",
        p: 4,
      }}
    >
      {loading ? (
        <CircularProgress /> 
      ) : success ? (
        <>
          <CheckCircleOutline sx={{ fontSize: 80, color: "green", mb: 2 }} />
          <Typography variant="h4" sx={{ mb: 2 }}>
            Compra Exitosa!
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Tu pago ha sido procesado con éxito. ¡Gracias por tu compra!
          </Typography>
          {membershipBalance !== null && (
            <Typography variant="h6" sx={{ mb: 4 }}>
              Tu nuevo saldo de membresía es: ${membershipBalance.toLocaleString()}
            </Typography>
          )}
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 2,
              backgroundColor: 'red',
              color: 'white',
              fontWeight: 'bold',
              padding: '8px',
              borderRadius: '4px',
            }}
          >
            Serás redirigido a la página principal en {timer} segundos.
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "green", color: "white", "&:hover": { bgcolor: "darkgreen" }, mt: 2 }}
            onClick={handleGoHome}
          >
            Sigue disfrutando de tus descuentos 
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h4" sx={{ mb: 2, color: "red" }}>
            Error en el Pago
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Hubo un problema al procesar tu pago. Por favor, intenta de nuevo.
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 2,
              backgroundColor: 'red',
              color: 'white',
              fontWeight: 'bold',
              padding: '8px',
              borderRadius: '4px',
            }}
          >
            Serás redirigido a la página principal en {timer} segundos.
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "gray", color: "white", "&:hover": { bgcolor: "darkgray" }, mt: 2 }}
            onClick={handleGoHome}
          >
            Volver a la Página Principal
          </Button>
        </>
      )}
    </Box>
  );
};
