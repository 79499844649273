import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import { getCoupon } from "../../client/apiCoupons";

const CouponInput = ({ selectedMembership, onApplyCoupon, setIsCouponValid, user, setAuthOpen, setCoupon, coupon }) => {

  const [couponApplied, setCouponApplied] = useState(false);
  const [couponMessage, setCouponMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Resetear el estado del cupón si cambia la membresía seleccionada
    if (
      !selectedMembership ||
      selectedMembership.type !== "MENSUAL"
    ) {
      setCoupon("");
      setCouponApplied(false);
      setCouponMessage("");
      setIsError(false);
      onApplyCoupon(0, false); // Reinicia el descuento
    }
  }, [selectedMembership]);
  

  const handleCouponApply = async () => {

    if (!user) {
        setAuthOpen(true);
        return;
      }

    if (!coupon.trim()) {
      setCouponMessage("Por favor, ingresa un cupón válido.");
      setIsError(true);
      return;
    }
  
    if (
      !selectedMembership ||
      selectedMembership.type !== "MENSUAL"
    ) {
      setCouponMessage("El cupón solo puede aplicarse a la membresía de tipo Mensual.");
      setIsCouponValid(false);
      setIsError(true);
      return;
    }
  
    try {
      const response = await getCoupon({ coupon });
      if (response && response.enable) {
        setCouponMessage("¡Cupón aplicado correctamente! Disfruta tu descuento.");
        setIsError(false);
        setCouponApplied(true);
        onApplyCoupon(response.amount, true);
      } else {
        setCouponMessage("El cupón ingresado no es válido o ha expirado.");
        setIsCouponValid(false);
        setIsError(true);
      }
    } catch (error) {
      setCouponMessage("Ocurrió un error al validar el cupón. Por favor, inténtalo de nuevo.");
      setIsCouponValid(false);
      setIsError(true);
    }
  };
  

  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        ¿Tienes un cupón? Ingrésalo aquí:
      </Typography>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
        <TextField
          label="Cupón"
          variant="outlined"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
          sx={{ width: "250px" }}
          disabled={couponApplied}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCouponApply}
          disabled={couponApplied}
        >
          Aplicar
        </Button>
      </Box>
      {couponMessage && (
        <Alert
          severity={isError ? "error" : "success"}
          sx={{ mt: 2, textAlign: "center" }}
        >
          {couponMessage}
        </Alert>
      )}
    </Box>
  );
};

export default CouponInput;
