import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const MembershipCard = ({ membership, onClick, isSelected }) => (
  <Card
    variant="outlined"
    onClick={() => onClick(membership)}
    sx={{
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      backgroundColor: isSelected ? "#d4edda" : "white",
      borderColor: isSelected ? "green" : "grey",
      "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: 6,
        cursor: "pointer",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    }}
  >
    <CardContent sx={{ textAlign: "center" }}>
      <Box>
        <Typography
          variant="h6"
          component="h3"
          sx={{ fontSize: "1.2rem", fontWeight: "bold", color: "green" }}
        >
          {membership.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 1,
            fontSize: "1rem",
            backgroundColor: "#FFE4B2",
            borderRadius: "10px",
            padding: "5px",
          }}
        >
          <strong>${Number(membership.amount).toLocaleString("es-CL")}</strong>
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, fontSize: "0.9rem" }}>
          {membership.description}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

export default MembershipCard;
