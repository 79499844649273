import React from "react";
import { Card, Grid, Typography } from "@mui/material";

const PaymentMethods = ({ methods, selectedMethod, onSelect }) => (
  <Grid container spacing={3} justifyContent="flex-start">
    {methods.map((method) => (
      <Grid item xs={12} md={6} key={method.value}>
        <Card
          variant="outlined"
          onClick={() => onSelect(method.value)}
          sx={{
            p: 3,
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            backgroundColor: selectedMethod === method.value ? "#FFE4B2" : "white",
            borderColor: selectedMethod === method.value ? "orange" : "grey",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: 6,
              cursor: "pointer",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Espaciado uniforme
            alignItems: "center",
            height: "200px", // Altura fija para igualar tamaños
          }}
        >
          {method.image && (
            <img
              src={method.image}
              alt={method.label}
              style={{
                width: "80%",
                marginBottom: "10px",
                height: "80px", // Tamaño uniforme de la imagen
                objectFit: "contain",
              }}
            />
          )}
          <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center" }}>
            {method.label}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
            {method.description}
          </Typography>
        </Card>
      </Grid>
    ))}
  </Grid>
);

export default PaymentMethods;
