import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { getSalesOrderById } from "../../client/apiSalesOrder";
import { updateSalesOrderDetailStatus } from "../../client/apiSalesOrderDetails";

export const ManageOrdersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(null);
  const [detailStatuses, setDetailStatuses] = useState({});
  const userId = localStorage.getItem('userId');

  console.log("userId:", userId);

  const handleSearch = async () => {
    if (!searchTerm) {
      alert("Por favor, ingresa un ID de pedido.");
      return;
    }

    try {
      const response = await getSalesOrderById(searchTerm); // Llama al endpoint
      setOrderData(response); // Almacena los datos de la orden en el estado

      // Inicializa los estados de cada detalle con su estado actual
      setDetailStatuses(
        response.saleDetails.reduce((acc, detail) => {
          acc[detail.id] = detail.soDetailStatus?.name || ""; // Usa el estado actual
          return acc;
        }, {})
      );

      setError(null); // Limpia errores anteriores
    } catch (err) {
      console.error("Error al buscar la orden:", err);
      setError("No se encontró la orden o ocurrió un error.");
      setOrderData(null); // Limpia los datos de la orden si hay un error
    }
  };

  const handleStatusChange = (detailId, status) => {
    setDetailStatuses((prevStatuses) => ({
      ...prevStatuses,
      [detailId]: status,
    }));
  };

  const handleSave = async (detailId) => {
    const status = detailStatuses[detailId];
    if (!status) {
      alert("Por favor, selecciona un estado.");
      return;
    }

    try {
      await updateSalesOrderDetailStatus(detailId, status, userId); // Llama al endpoint de actualización
      alert(`Estado del detalle ${detailId} actualizado a "${status}".`);
    } catch (error) {
      console.error(`Error al actualizar el detalle ${detailId}:`, error);
      alert("Error al actualizar el estado del detalle.");
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, mx: "auto", p: 2, textAlign: "center" }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Buscar Órdenes
      </Typography>

      {/* Campo de búsqueda */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TextField
          label="ID del Pedido"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ maxWidth: 400 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          sx={{
            bgcolor: "green",
            "&:hover": {
              bgcolor: "darkgreen",
            },
          }}
        >
          Buscar
        </Button>
      </Box>

      {/* Mostrar datos de la orden o error */}
      {orderData && (
        <Box sx={{ mt: 4 }}>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h6">Detalles de la Orden</Typography>
              <Typography><strong>ID:</strong> {orderData.id}</Typography>
              <Typography><strong>Cliente:</strong> {orderData.client.name} {orderData.client.last_name}</Typography>
              <Typography><strong>Fecha de Compra:</strong> {new Date(orderData.created_at).toLocaleDateString()}</Typography>
            </CardContent>
          </Card>

          <Typography variant="h6" align="left" sx={{ mb: 2 }}>
            Detalle de Productos
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Producto</strong></TableCell>
                <TableCell><strong>Cantidad</strong></TableCell>
                <TableCell><strong>Precio Unitario</strong></TableCell>
                <TableCell><strong>Subtotal</strong></TableCell>
                <TableCell><strong>Estado</strong></TableCell>
                <TableCell><strong>Acción</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData.saleDetails.map((detail) => (
                <TableRow key={detail.id}>
                  <TableCell>{detail.product.name}</TableCell>
                  <TableCell>{detail.quantity}</TableCell>
                  <TableCell>${detail.unit_price}</TableCell>
                  <TableCell>${detail.subtotal}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        value={detailStatuses[detail.id] || ""}
                        onChange={(e) => handleStatusChange(detail.id, e.target.value)}
                      >
                        <MenuItem value="Faltante">Faltante</MenuItem>
                        <MenuItem value="Pendiente de pago">Pendiente de pago</MenuItem>
                        <MenuItem value="Entregado">Entregado</MenuItem>
                        <MenuItem value="Comprado">Comprado</MenuItem>
                        <MenuItem value="Consolidado">Consolidado</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSave(detail.id)}
                    >
                      Guardar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      {error && (
        <Box sx={{ mt: 4, color: "red" }}>
          <Typography>{error}</Typography>
        </Box>
      )}
    </Box>
  );
};
