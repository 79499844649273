import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";

export const SubscriptionPage = () => {
  const [formData, setFormData] = useState({
    rut: "",
    name: "",
    phone: "",
    termsAccepted: false,
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulariolk
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom textAlign="center">
        Suscribete a T'akay Gold y sorpréndete con el ahorro
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          id="rut"
          label="RUT"
          name="rut"
          variant="outlined"
          value={formData.rut}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          id="name"
          label="Nombre"
          name="name"
          variant="outlined"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          id="phone"
          label="Teléfono"
          name="phone"
          variant="outlined"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleChange}
              required
            />
          }
          label="Acepto los Términos y Condiciones"
        />
        <Box textAlign="center" mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formData.termsAccepted}
          >
            Quiero ser GOLD
          </Button>
        </Box>
      </form>
    </Container>
  );
};
