import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { getRegions } from "../../client/apiRegions";
import { getCities } from "../../client/apiCities";
import { createDirections } from "../../client/apiDirections";

const AddAddressModal = ({ open, onClose, client, fetchClientData }) => {
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    clientId: client?.id || {},
    address: "",
    number: "",
    cityId: "",
    region: "",
    department: "",
    additionalInfo: "",
  });

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const regionsData = await getRegions();
        setRegions(regionsData);
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };
    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const citiesData = await getCities();
        setCities(citiesData);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    fetchCities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await createDirections(formData);
      setSuccessSnackbarOpen(true);
      fetchClientData(); // Recarga las direcciones en el componente padre
      setFormData({
        clientId: client.id,
        address: "",
        number: "",
        cityId: "",
        region: "",
        department: "",
        additionalInfo: "",
      });
      onClose();
    } catch (error) {
      console.error("Error saving address:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ color: green[700] }}>Agregar Nueva Dirección</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Grid container spacing={2}>
              {/* Calle */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Calle"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    sx: {
                      color: green[700],
                      "&.Mui-focused": {
                        color: green[700],
                      },
                    },
                  }}
                  InputProps={{
                    sx: {
                      height: 40,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: green[500] },
                      "&:hover fieldset": { borderColor: green[700] },
                      "&.Mui-focused fieldset": { borderColor: green[500] },
                    },
                  }}
                />
              </Grid>

              {/* Número */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Número"
                  name="number"
                  value={formData.number}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    sx: {
                      color: green[700],
                      "&.Mui-focused": {
                        color: green[700],
                      },
                    },
                  }}
                  InputProps={{
                    sx: {
                      height: 40,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: green[500] },
                      "&:hover fieldset": { borderColor: green[700] },
                      "&.Mui-focused fieldset": { borderColor: green[500] },
                    },
                  }}
                />
              </Grid>

              {/* Región */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: green[500] },
                    "&:hover fieldset": { borderColor: green[700] },
                    "&.Mui-focused fieldset": { borderColor: green[500] },
                    height: 40,
                  },
                }}>
                  <InputLabel
                    sx={{
                      color: green[700],
                      "&.Mui-focused": {
                        color: green[700],
                      },
                    }}
                  >
                    Región
                  </InputLabel>
                  <Select
                    name="region"
                    value={formData.region}
                    onChange={handleInputChange}
                    label="Región"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: green[500] },
                        "&:hover fieldset": { borderColor: green[700] },
                        "&.Mui-focused fieldset": { borderColor: green[500] },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Seleccione una región</em>
                    </MenuItem>
                    {regions.map((region) => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Comuna */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: green[500] },
                    "&:hover fieldset": { borderColor: green[700] },
                    "&.Mui-focused fieldset": { borderColor: green[500] },
                    height: 40,
                  },
                }}>
                  <InputLabel
                    sx={{
                      color: green[700],
                      "&.Mui-focused": {
                        color: green[700],
                      },
                    }}
                  >
                    Comuna
                  </InputLabel>
                  <Select
                    name="cityId"
                    value={formData.cityId}
                    onChange={handleInputChange}
                    label="Comuna"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: green[500] },
                        "&:hover fieldset": { borderColor: green[700] },
                        "&.Mui-focused fieldset": { borderColor: green[500] },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Seleccione una comuna</em>
                    </MenuItem>
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Departamento o Casa */}
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: green[500] },
                    "&:hover fieldset": { borderColor: green[700] },
                    "&.Mui-focused fieldset": { borderColor: green[500] },
                    height: 40,
                  },
                }}>
                  <InputLabel
                    sx={{
                      color: green[700],
                      "&.Mui-focused": {
                        color: green[700],
                      },
                    }}
                  >
                    Departamento o Casa
                  </InputLabel>
                  <Select
                    name="department"
                    value={formData.department}
                    onChange={handleInputChange}
                    label="Departamento o Casa"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: green[500] },
                        "&:hover fieldset": { borderColor: green[700] },
                        "&.Mui-focused fieldset": { borderColor: green[500] },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Seleccione una opción</em>
                    </MenuItem>
                    <MenuItem value="Casa">Casa</MenuItem>
                    <MenuItem value="Departamento">Departamento</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Referencias */}
              <Grid item xs={12}>
                <TextField
                  label="Referencias"
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  InputLabelProps={{
                    sx: {
                      color: green[700],
                      "&.Mui-focused": {
                        color: green[700],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: green[500] },
                      "&:hover fieldset": { borderColor: green[700] },
                      "&.Mui-focused fieldset": { borderColor: green[500] },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={loading}
            sx={{
              backgroundColor: green[500],
              "&:hover": { backgroundColor: green[700] },
            }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar de éxito */}
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Dirección guardada correctamente.
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddAddressModal;
