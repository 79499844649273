import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, TextField, Typography, Divider, useMediaQuery } from '@mui/material';
import { AccountCircle, ListAlt, LocationOn, MonetizationOn } from '@mui/icons-material';
import { green } from '@mui/material/colors';

import dayjs from 'dayjs';
import { getClientById, updateClientData } from '../../client/apiClients'; 
import { getSalesOrderClientById } from '../../client/apiSalesOrder';
import { getMembershipsByClient } from '../../client/apiMemberships';
import { getDirectionsByClient } from '../../client/apiDirections';
import { AuthContext } from '../../context/AuthContext';
import AccountContent from './AccountContent';

export const Account = () => {
  const { userId } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [directions, setDirections] = useState([]);
  const [selectedTab, setSelectedTab] = useState('personalData');
  const [clientData, setClientData] = useState({
    name: '',
    last_name: '',
    rut: '',
    email: '',
    phone: '',
    date_of_birthday: null,
    balance: 0
  });

  // Detectar si la pantalla es pequeña
  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const data = await getClientById(userId);
        const salesOrders = await getSalesOrderClientById(userId);
        const membershipsHistory = await getMembershipsByClient(userId);
        const directionsClients = await getDirectionsByClient(userId);
        setOrders(salesOrders);
        setMemberships(membershipsHistory);
        setDirections(directionsClients);

        const birthday = data.date_of_birthday ? dayjs(data.date_of_birthday) : null;

        setClientData({
          name: data.name || '',
          last_name: data.last_name || '',
          rut: data.rut || '',
          email: data.email || '',
          phone: data.phone || '',
          date_of_birthday: birthday,
          balance: data.balance
        });
      } catch (error) {
        console.error('Error al obtener los datos del cliente:', error);
      }
    };

    if (userId) {
      fetchClientData();
    }
  }, [userId]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({
      ...clientData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    const { rut, ...updatedData } = {
      ...clientData,
      date_of_birthday: clientData.date_of_birthday ? clientData.date_of_birthday.format('YYYY-MM-DD') : null, 
      userId, 
    };

    try {
      await updateClientData(updatedData); 
      alert('Perfil actualizado con éxito');
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      alert('Error al actualizar el perfil');
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={isMobile ? 1 : 2}> {/* Ajusta el padding en móviles */}
      <Box 
        display="flex" 
        justifyContent={isMobile ? 'center' : 'space-between'} 
        mb={isMobile ? 1 : 2} 
        flexWrap={isMobile ? 'wrap' : 'nowrap'} 
        gap={isMobile ? 1 : 0}
      >
        {isMobile ? (
          <>
            <Box sx={{ display: 'flex', gap: 1, width: '100%', justifyContent: 'center' }}>
              <Button
                variant={selectedTab === 'personalData' ? 'contained' : 'outlined'}
                onClick={() => handleTabChange('personalData')}
                startIcon={<AccountCircle />}
                sx={{
                  flex: 1, // Hacer que los botones tengan el mismo ancho
                  backgroundColor: selectedTab === 'personalData' ? 'green' : 'transparent',
                  color: selectedTab === 'personalData' ? 'white' : 'green',
                  borderColor: 'green',
                  '&:hover': {
                    backgroundColor: selectedTab === 'personalData' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
                  },
                }}
              >
                Datos personales
              </Button>

              <Button
                variant={selectedTab === 'orders' ? 'contained' : 'outlined'}
                onClick={() => handleTabChange('orders')}
                startIcon={<ListAlt />}
                sx={{
                  flex: 1,
                  backgroundColor: selectedTab === 'orders' ? 'green' : 'transparent',
                  color: selectedTab === 'orders' ? 'white' : 'green',
                  borderColor: 'green',
                  '&:hover': {
                    backgroundColor: selectedTab === 'orders' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
                  },
                }}
              >
                Pedidos
              </Button>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, width: '100%', justifyContent: 'center' }}>
              <Button
                variant={selectedTab === 'addresses' ? 'contained' : 'outlined'}
                onClick={() => handleTabChange('addresses')}
                startIcon={<LocationOn />}
                sx={{
                  flex: 1,
                  backgroundColor: selectedTab === 'addresses' ? 'green' : 'transparent',
                  color: selectedTab === 'addresses' ? 'white' : 'green',
                  borderColor: 'green',
                  '&:hover': {
                    backgroundColor: selectedTab === 'addresses' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
                  },
                }}
              >
                Direcciones
              </Button>

              <Button
                variant={selectedTab === 'membershipBalance' ? 'contained' : 'outlined'}
                onClick={() => handleTabChange('membershipBalance')}
                startIcon={<MonetizationOn />}
                sx={{
                  flex: 1,
                  backgroundColor: selectedTab === 'membershipBalance' ? 'green' : 'transparent',
                  color: selectedTab === 'membershipBalance' ? 'white' : 'green',
                  borderColor: 'green',
                  '&:hover': {
                    backgroundColor: selectedTab === 'membershipBalance' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
                  },
                }}
              >
                Saldo membresía
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="center" mb={isMobile ? 1 : 2} flexWrap="wrap"> {/* Permitir que los botones se acomoden en móviles */}
        <Button
          variant={selectedTab === 'personalData' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('personalData')}
          startIcon={<AccountCircle />}
          sx={{
            mx: isMobile ? 0.5 : 1, // Reducir los márgenes entre botones en móviles
            mb: isMobile ? 1 : 0,
            backgroundColor: selectedTab === 'personalData' ? 'green' : 'transparent',
            color: selectedTab === 'personalData' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'personalData' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Datos personales
        </Button>

        <Button
          variant={selectedTab === 'orders' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('orders')}
          startIcon={<ListAlt />}
          sx={{
            mx: isMobile ? 0.5 : 1,
            mb: isMobile ? 1 : 0,
            backgroundColor: selectedTab === 'orders' ? 'green' : 'transparent',
            color: selectedTab === 'orders' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'orders' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Pedidos
        </Button>

        <Button
          variant={selectedTab === 'addresses' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('addresses')}
          startIcon={<LocationOn />}
          sx={{
            mx: isMobile ? 0.5 : 1,
            mb: isMobile ? 1 : 0,
            backgroundColor: selectedTab === 'addresses' ? 'green' : 'transparent',
            color: selectedTab === 'addresses' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'addresses' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Direcciones
        </Button>

        <Button
          variant={selectedTab === 'membershipBalance' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('membershipBalance')}
          startIcon={<MonetizationOn />}
          sx={{
            mx: isMobile ? 0.5 : 1,
            mb: isMobile ? 1 : 0,
            backgroundColor: selectedTab === 'membershipBalance' ? 'green' : 'transparent',
            color: selectedTab === 'membershipBalance' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'membershipBalance' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Saldo membresía
        </Button>
      </Box>
          </>
        )}
      </Box>
      <Box 
    sx={{
      width: '100%',
      maxWidth: 800,
      textAlign: 'center',
      mt: 2,
      mb: isMobile ? 1 : 3,
      bgcolor: green[50],
      p: 2,
      borderRadius: 2,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: 'bold', color: green[800] }}>
      Tu saldo a favor para tu próximo pedido es:
    </Typography>
    <Typography variant="h4" sx={{ fontWeight: 'bold', color: green[900] }}>
      {clientData.balance !== undefined
        ? clientData.balance.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })
        : 'Cargando...'}
    </Typography>
  </Box>
      <Divider sx={{ width: '100%', mb: isMobile ? 1 : 2 }} />

      <AccountContent
        selectedTab={selectedTab}
        clientData={clientData}
        handleChange={handleChange}
        setClientData={setClientData}
        handleUpdate={handleUpdate}
        isMobile={isMobile}
        orders={orders}
        directions={directions}
        memberships={memberships}
      />
    </Box>
  );
};
