import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";

export const AdminPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: "bold",
          textAlign: "center",
          color: "green",
        }}
      >
        Admin Panel
      </Typography>

      {/* Botones de navegación */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, flexWrap: "wrap" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/change-status-orders")}
        >
          Cambiar Estado de Pedido
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/change-status-membership")}
        >
          Actualizar Estado de Membresía
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/upload-price")}
        >
          Cambio de Precio
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/consolidated-order")}
        >
          Órdenes Consolidadas
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/manage-details")}
        >
          Cambiar Estado de Detalle
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/disable-products")}
        >
          Habilitar/Deshabilitar Productos
        </Button>
      </Box>
    </Container>
  );
};
